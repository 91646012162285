html {
  min-height: 100vh;
  padding: 0;
}

body {
  color: #fff;
  background: linear-gradient(#1a0e20 0%, #010013 100%);
  min-height: 100vh;
}

.testimonialLowerContainer {
  overflow: hidden;
}

.typoColorWhite {
  color: #d9d6df !important;
}

.accordianGapList {
  --bs-accordion-border-width: 0px;
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.nonBorder {
  border: none;
}

.ourTeamContainer {
  flex-direction: column;
  gap: 150px;
  display: flex;
}

.baseBackgroundInherit {
  background: linear-gradient(#1a0e20 10%, #010013 90%) !important;
}

.accordianPaddedContent {
  padding: 16px;
  padding-top: 24px !important;
}

.accordianListItem {
  border-top: 1px solid #9a9daa;
  border-bottom: 1px solid #9a9daa;
  padding: 24px 16px;
}

.outerContainer {
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  height: 100%;
  display: flex;
}

.nav {
  color: #fff;
  justify-content: space-between;
  width: 1100px;
  height: 84px;
  padding: 12px;
  display: flex;
}

.letsTalkButton {
  cursor: pointer;
  width: 120px;
  height: auto;
}

.navbarIcons {
  width: 400px;
  height: auto;
}

.servicesListOuterContainer {
  justify-content: center;
  margin-top: 100px;
  display: flex;
}

.optionsContainer {
  gap: 12px;
  display: flex;
}

.navButton {
  text-align: left;
  color: #fff;
  background: #8d49e4;
  border: 1.5px solid #fff;
  border-radius: 15px;
  width: 127px;
  height: 40px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  overflow: hidden;
}

.servicesContainer {
  width: 1180px;
  height: auto;
  opacity: 0px;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
}

.serviceList {
  width: 376px;
  height: 330px;
  opacity: 0px;
  opacity: 0px;
  background: linear-gradient(90.61deg, #5f01bd 5%, #3c0475 115.73%);
  border: 1px solid;
  border-radius: 8px;
  gap: 0;
}

.detailsList {
  color: #fff;
}

.upperServicesContainer {
  gap: 16px;
  display: flex;
}

.blogPrimaryTypo {
  letter-spacing: .05em;
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
}

.blogMainTypo {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}

.viewAllBlog {
  border: 2px;
  border-radius: 40px;
  gap: 4px;
  width: 194px;
}

.mainBlog {
  flex-direction: column;
  display: flex;
}

.mainBlogHeading {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.blogCard {
  gap: 32px;
  width: 544px;
  height: 200px;
  display: flex;
}

.blogItemPrimaryTypo {
  letter-spacing: .05em;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
}

.blogItemMainTypo {
  color: #fff;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
}

.blogItemContent {
  flex-direction: column;
  display: flex;
}

.blogCardItemImage {
  border-radius: 8px;
  width: 200px;
  height: 200px;
}

.blogCardContainer {
  max-width: 1, 168px;
  flex-wrap: wrap;
  gap: 80px;
  height: auto;
  display: flex;
}

.blogsItemsMainList {
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  display: flex;
}

.getInTouch {
  width: 154px;
  height: 46px;
  opacity: 0px;
  text-align: left;
  cursor: pointer;
  background: #8d49e4;
  border-radius: 24px;
  gap: 12px;
  padding: 12px 16px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
}

.lowerServiceContainer {
  flex-direction: row-reverse;
  gap: 12px;
  display: flex;
}

.servicesMainTypo {
  text-align: left;
  color: #fff;
  line-break: auto;
  font-family: Raleway;
  font-size: 44px;
  font-weight: 600;
  line-height: 64px;
}

.servicesPrimaryTypo {
  letter-spacing: 3px;
  text-align: left;
  color: #ceced7;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
}

.servicesTertiaryTypo {
  text-align: left;
  color: #ceced7;
  font-family: Avenir;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.servicesOuterContainer {
  justify-content: center;
  width: 100%;
  display: flex;
}

.ourPhilosophyOuterContainer {
  justify-content: center;
  margin-top: 150px;
  display: flex;
  overflow-x: hidden;
}

.ourPhilosophyContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.lowerPhilosiphyContainer {
  display: flex;
}

.philosiphyListItem {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 378px;
  display: flex;
}

.mediumText {
  text-align: left;
  color: #d9d6df;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.mediumSmallText {
  text-align: left;
  color: #cdc9e4;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

.mediumLargeText {
  text-align: center;
  color: #b8b3c1;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.partnersLogoContainer {
  border: 1px solid #9a9daa;
  border-radius: 8px;
  justify-content: space-evenly;
  align-items: center;
  gap: 0;
  width: 1176px;
  height: 149px;
  padding: 24px;
  display: flex;
}

.secondaryServicesListContainer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 1200px;
  display: flex;
}

.serviceItemMainText {
  margin-top: 5px;
}

.secondaryServicesListItem {
  width: 376px;
  height: auto;
  opacity: 0px;
  border: 1px solid #9a9daa;
  border-radius: 12px;
  justify-content: flex-start;
  gap: 4px;
  padding: 24px;
}

.redirectSmallText {
  text-align: left;
  color: #9747ff;
  align-items: center;
  height: 100%;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  display: flex;
}

.learnMoreContainer {
  text-align: center;
  align-items: center;
  width: auto;
  padding: 12px;
  display: flex;
}

.centerAlligned {
  justify-content: center;
  text-align: center !important;
}

.verticalCentered {
  flex-direction: column;
  display: flex;
  align-items: center !important;
}

.ourTeamsTabTypo {
  color: #c4c7ce !important;
}

.active {
  color: #9747ff !important;
  background-color: inherit !important;
  border-color: linear-gradient(180deg, #1a0e20 0%, #010013 100%) !important;
}

.teamMemberList {
  flex-wrap: wrap !important;
  gap: 24px !important;
  display: flex !important;
}

.columnDirection {
  flex-direction: column;
}

.flexCentered {
  align-items: center;
  text-align: center !important;
  justify-content: center !important;
  display: flex !important;
}

.tab-pane:not(.show) {
  display: none;
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-tabs .nav-link {
  border: none !important;
}

.teamMember {
  width: 376px;
  height: 385px;
}

.ourTeamMainText {
  color: #d9d6df;
  width: 100%;
  max-width: 600px;
  text-align: center !important;
}

.innovationListContainer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
}

.pioneerTwo {
  opacity: .89;
  background: url("PioneerTwo.c837cab7.svg");
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-end;
  width: 370px;
  min-height: 370px;
  padding: 8px 24px;
  display: flex;
  overflow: hidden;
}

.pioneerThree {
  opacity: .89;
  background: url("atankshuCard.68d6fe5a.svg");
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-end;
  width: 370px;
  min-height: 370px;
  padding: 8px 24px;
  display: flex;
  overflow: hidden;
}

.engPioneerTwo {
  background: url("rishabCard.56c5c201.svg") !important;
}

.engPioneerThree {
  background: url("yuvrajCard.3d580342.svg") !important;
}

.designPioneerTwo {
  background: url("surjithCard.1887aa8f.svg") !important;
}

.nonePadd {
  padding: 0 !important;
}

.ourTeamCardDetail {
  width: 376px;
  height: 107px;
  opacity: .89px;
  background: linear-gradient(90.61deg, #5f01bd 5%, #b53eb1 115.73%);
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px 16px;
  display: flex;
}

.noStylemediumText {
  text-align: left;
  color: #fff;
  max-height: 24px;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.noStylemediumSmallText {
  text-align: left;
  color: #cdc9e4;
  max-height: 24px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 21.86px;
}

.testimonialsContainer {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 1100px;
  display: flex;
}

.testimonialTopContainer {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.testimonialTextContainer {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.typoSmall {
  letter-spacing: 3px;
  text-align: left;
  color: #c4c7ce;
  max-height: 14px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
}

.typoMedium {
  text-align: left;
  color: #b8b3c1;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.circularContainer {
  border: 1px solid #9a9daa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
}

.arrowsContainer {
  gap: 24px;
  display: flex;
}

.lineHeightBig {
  line-height: 40px !important;
}

.accordion-button:after {
  background: url("dropdownIcon.2935325a.svg") !important;
  width: 40px !important;
  height: 40px !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed):after {
  background: url("dropdownIcon.2935325a.svg") !important;
  width: 40px !important;
  height: 40px !important;
}

.lowerContainer {
  gap: 18px;
  display: flex;
}

.testimonialListItem {
  min-width: 776px;
  height: 259px;
  opacity: .1px;
  background: #4f4872;
  border-radius: 24px;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  display: flex;
}

.typoMediumSmall {
  text-align: left;
  color: #fff;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.32px;
}

.minorColored {
  color: #cdc9e4;
}

.clientImage {
  width: auto;
  height: 40px;
  margin-top: 24px;
}

.blogMainContentContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 900px;
  display: flex;
}

.blogTopContainer {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.blogsMainContainer {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 1100px;
  display: flex;
}

.blogMiddleContainer {
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.viewAllButton {
  cursor: pointer;
  width: 120px;
  height: auto;
  margin: auto;
}

.toolsListImage {
  margin-top: 24px;
}

.hiringCardItem {
  width: 1100px;
  height: 122px;
  opacity: 0px;
  background-color: #352a41;
  border-left: 8px solid #5f01bd;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  padding: 28px 32px;
  display: flex;
}

.hiringCardText {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.horizontallycenterAlligned {
  text-align: center;
  justify-content: center;
}

.rearMostContainer {
  background-color: #5f01bd;
  border: 1px solid #9a9daa;
  justify-content: space-between;
  width: 100%;
  height: 800px;
  padding: 24px 0;
  display: flex;
}

.rearMostLeftContainer {
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  padding-left: 80px;
  display: flex;
}

.whiteColor {
  color: #fff;
}

.secondaryRearLeftContainer {
  flex-direction: column;
  display: flex;
}

.topRearLeftContainer {
  flex-direction: column;
  gap: 48px;
  display: flex;
}

.bottomLeftRear {
  width: 160px;
  height: 108px;
}

.sachhsoftIcon {
  width: 100px;
  height: auto;
}

.rearMostRightContainer {
  flex-direction: column-reverse;
  padding: 8px 0;
  display: flex;
  overflow: hidden;
}

.bottomFormContainer {
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  gap: 28px;
  width: 708px;
  height: 672px;
  padding: 24px 100px 24px 24px;
  display: flex;
  overflow: hidden;
}

.tag {
  min-width: 130px;
  height: 38px;
  opacity: 0px;
  text-align: left;
  color: #030114;
  border: 1px solid #000;
  border-radius: 24px;
  gap: 12px;
  padding: 8px 16px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  display: flex;
  overflow: hidden;
}

.boldMedium {
  text-align: left;
  max-height: 18px;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.13px;
}

.formTagsContainer {
  gap: 12px;
  display: flex;
}

.darkColor {
  color: #030114;
}

.textInputContainer {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.inputLabel {
  text-align: left;
  vertical-align: middle;
  height: 100%;
  max-height: 16px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
}

.flowSteps {
  max-width: 544px;
  max-height: 544px;
}

.inputTypeText {
  background-color: #fff;
  border: 1px solid #9a9daa;
  border-radius: 6px;
  width: 100%;
  min-height: 44px;
  display: flex;
}

.formRowContainer {
  gap: 12px;
  display: flex;
}

.textArea {
  background-color: #fff;
  border: 1px solid #9a9daa;
  border-radius: 6px;
  width: 100%;
  min-height: 108px;
  display: flex;
  overflow: scroll;
}

.attachmentContainer {
  gap: 4px;
  display: flex;
}

.attachmentLinkIcon {
  width: 16px;
  height: 16px;
}

.submitButton {
  width: 115px;
  height: auto;
}

.columedContainer {
  flex-direction: column;
  align-items: center;
  width: auto;
  display: flex;
}

.aboutUsSec {
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  padding: 16px;
  display: grid;
}

.aboutUsSecMain {
  text-align: left;
  color: #d9d6df;
  max-width: 364px;
  height: auto;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.aboutUsSecContent {
  text-align: left;
  color: #cdc9e4;
  width: 676px;
  height: auto;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 350;
  line-height: 36px;
}

.leftMover {
  flex-direction: row-reverse;
  width: 100%;
  height: auto;
  display: flex;
}

.horizontalRow {
  min-width: 676px;
  height: 0;
  opacity: 0px;
  border: 1px solid #9a9daa;
}

.typoMediumLarge {
  text-align: left;
  color: #d9d6df;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.typoBiggerSmall {
  text-align: center;
  color: #cdc9e4;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.spacedFlex {
  justify-content: space-between;
  display: flex;
}

.ourApproachCard {
  flex-direction: column;
  gap: 16px;
  max-width: 326px;
  height: auto;
  display: flex;
}

.outApproachCardIcon {
  width: 48px;
  height: 48px;
}

.outApproachCardContent {
  text-align: left;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

.ourApproachTitle {
  letter-spacing: 3px;
  color: #c4c7ce;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
}

.outApproachSecTitle {
  color: #d9d6df;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.capture {
  width: 100%;
  height: auto;
}

.ourBeliefTextContainer {
  flex-direction: column;
  display: flex;
}

.ourBeliefHeading {
  letter-spacing: 3px;
  text-align: left;
  color: #c4c7ce;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.44px;
}

.ourBeliefTertiary {
  text-align: left;
  color: #cdc9e4;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.ourBeliefImage {
  bottom: 160px;
}

.absolute {
  position: absolute;
}

.meetTheTeamButton {
  width: 171px;
  height: 46px;
}

.ourBeliefContainer {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.outBeliefButtonContainer {
  justify-content: center;
  padding: 12px;
  display: flex;
}

.aboutUsContainer {
  background: linear-gradient(#1a0e20 0%, #010013 100%);
  flex-direction: column;
  gap: 120px;
  display: flex;
}

.accordion {
  border-color: linear-gradient(180deg, #1a0e20 0%, #010013 100%) !important;
}

.fullContainer {
  width: 100%;
  height: auto;
}

.baseBackground {
  background: linear-gradient(#1a0e20 0%, #010013 100%);
}

.innovationPioneers {
  max-width: 370px;
}

.navItemsTypo {
  color: #fff;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  text-decoration: none;
}

.gappedTen {
  gap: 25px;
  display: flex;
}

.fullWidthCentered {
  justify-content: center;
  width: 100%;
}

.defaultMaxWidth {
  max-width: 1176px !important;
}

.ourTeamPrimaryCompText {
  width: 100%;
  max-width: 525px;
}

.ourDreamTeamIcon {
  width: 100%;
  height: 273px;
}

.ourTeamPrimaryComp {
  justify-content: space-between;
  padding: 12px;
  display: flex;
}

.flexedWrap {
  flex-wrap: wrap;
  display: flex;
}

.servicesLeftContainerMaxWidth {
  justify-content: center;
  width: 100%;
  max-width: 680px;
  padding: 18px;
}

.flowStepsCardContent {
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  max-width: 544px;
  display: flex;
}

.flowStepsStep {
  letter-spacing: .05em;
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
}

.flowStepsMainContent {
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}

.flowStepMbSlider {
  justify-content: space-between;
  display: flex;
}

.flowStepsArrowMbContainer {
  display: none;
}

.flowStepListContainer {
  transition: transform .5s ease-in-out;
  display: flex;
  overflow: hidden;
}

.flowStepsListItem {
  gap: 48px;
  min-width: 100%;
  transition-duration: 1s;
  display: flex;
}

.flowStepsContentDescription {
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.flowStepsContent, .flexDirectionColumn {
  flex-direction: column;
  display: flex;
}

.flowStepsArrowContainer {
  gap: 16px;
  display: flex;
}

.centeredtext {
  text-align: center;
}

.horizontalDivider {
  background-color: #9a9daa;
  border: 1px solid #9a9daa;
  width: 476px;
}

.lineBreak {
  line-break: auto;
}

.ourProcess {
  flex-direction: column;
  gap: 12px;
  min-height: 150px;
  display: flex;
}

.ourProcessView {
  border-radius: 24px;
  width: 432px;
  height: 551.6px;
}

.innerOurProcessContainer {
  gap: 16px;
  display: flex;
}

.ourProcessRightContainer {
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-width: 500px;
  display: flex;
}

.technicalToolsetSlider {
  justify-content: space-between;
  gap: 24px;
  width: 1000px;
  max-width: 1176px;
  padding: 16px 0;
  display: flex;
  overflow: hidden;
}

.sliderArrow {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.technicalToolsetItemContainer {
  justify-content: space-evenly;
  gap: 60px;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.technicalToolsetItemIcons {
  max-width: 160px;
  max-height: 36px;
}

.boostBussinessContainer {
  display: flex;
}

.rearMostLowerContainer {
  display: none;
}

.ourClientsViews {
  max-width: 240px;
  height: 60px;
}

.fullWidthCover {
  width: 100%;
  max-width: 100%;
}

.rearMostLowestContainer {
  width: 100%;
  height: auto;
  display: none;
}

.navbar-toggler-icon {
  background: url("navbarToggleHead.db0e2310.svg") !important;
  width: 32px !important;
  height: 32px !important;
}

.exploreCareerButton {
  cursor: pointer;
  width: 288px;
  padding-top: 24px;
}

.fitInPlaceWidth {
  width: fit-content !important;
}

.careersPrimaryBlock {
  width: 100%;
}

.careersHorizontalScroll {
  background: linear-gradient(101.11deg, #8d49e4 0%, #b78cee 100%);
  justify-content: center;
  width: 100%;
  height: auto;
  display: flex;
}

.careersHorizontalScrollUpperContainer {
  flex-direction: column;
  display: flex;
}

.careersHorizontalScrollPrimaryText {
  letter-spacing: .05em;
  text-align: left;
  color: #e8dbfa;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
}

.baseFormFile {
  display: none;
}

.careersHorizontalScrollSecondaryText {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}

.scrollCardContainer {
  gap: 24px;
  display: flex;
  overflow: hidden;
}

.scrollCardIcon {
  width: 48px;
  height: 48px;
}

.scrollCardTypo {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
}

.careerHorizontalInnerContainer {
  padding: 72px 0;
}

.scrollCard {
  background: linear-gradient(110.39deg, #e5b7fb0f 0%, #f1d8fd0f 100%);
  border: 1px solid #b68aed;
  border-radius: 12px;
  flex-direction: column;
  gap: 24px;
  width: 280px;
  min-width: 280px;
  height: 230px;
  padding: 40px;
  display: flex;
}

.careersPrimaryBlockMainTypo {
  text-align: left;
  color: #fff;
  max-width: 800px;
  height: auto;
  font-family: Raleway;
  font-size: 64px;
  font-weight: 600;
  line-height: 83.2px;
}

.purpleColoredTypo {
  color: #8d49e4 !important;
}

.careersPrimaryBlockContentTypo {
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.currentOpeningOuterContainer {
  flex-direction: column;
  gap: 36px;
  width: 1180px;
  display: flex;
}

.currentOpeningUpperContainer {
  flex-direction: column;
  gap: 0;
  display: flex;
}

.careerJobType {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
}

.servicesPrimaryContentGradient {
  background: var(--Text-Gradient, linear-gradient(87deg, #d3b4fb 0%, #8d49e4 97.7%));
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.servicesTopLeftContainer {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.servicesTopContainer {
  align-self: stretch;
  align-items: center;
  gap: 80px;
  display: flex;
}

.servicesPrimaryContent {
  color: var(--Text-Primary, #fff);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.servicesSecondaryContent {
  color: var(--Text-Secondary, #d1cfd2);
  align-self: stretch;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.updatedBaseServicesContainer {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 80px;
  max-width: 1208px;
  display: flex;
}

.baseServicesLowerContainer {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  display: flex;
}

.baseServicesCardContainer {
  background: linear-gradient(110deg, #e5b7fb03 0%, #f1d8fd03 100%);
  border: 1px solid #4a454d;
  border-radius: 12px;
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 56px;
  min-width: 436px;
  height: 520px;
  padding: 64px;
  display: flex;
}

.exploreMoreRightIcon {
  width: 20px;
  height: 20px;
}

.exploreMoreButtonContentTypo {
  color: var(--Text-Primary, #fff);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.imgServicesDropdownPrimaryContainer {
  width: 80px;
  height: 80px;
}

.servicetopDropdownItem {
  gap: 56px;
  display: flex;
}

.servicesDropdownListContainer {
  color: var(--Text-Secondary, #d1cfd2);
  flex-direction: column;
  align-self: stretch;
  gap: 26px;
  padding: 0 144px 18px 152px;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  display: flex;
}

.servicesPagePrimaryInnerContainer {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  display: flex;
}

.servicesPagePrimaryContent {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  max-width: 800px;
  display: flex;
}

.servicesPagePrimaryMainContent {
  color: var(--Text-Primary, #fff);
  font-family: Manrope;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.purpleColoredPrimaryMainContent {
  color: #8d49e4;
  background-clip: text;
}

.servicesPageSecondaryMainContent {
  color: var(--Text-Secondary, #d1cfd2);
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.exploreMoreServicesButton {
  cursor: pointer;
  background: #8d49e4;
  border-radius: 40px;
  align-items: center;
  gap: 4px;
  padding: 15px 32px;
  display: flex;
}

.updatedBaseServicesOuterContainer {
  justify-content: center;
  display: flex;
}

.baseServicesContentContainer {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.baseServicesCardIcon {
  width: 120px;
  height: 120px;
}

.baseServicesCardContent {
  color: var(--Text-Secondary, #d1cfd2);
  align-self: stretch;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.baseServicesCardMainHeading {
  color: var(--Text-Primary, #fff);
  line-break: auto;
  align-self: stretch;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.careerJobExperience {
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.viewDetailsContent {
  text-align: left;
  color: #fff;
  margin-top: 16px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
}

.viewDetailsButton {
  cursor: pointer;
  border-radius: 40px;
  align-items: center;
  gap: 4px;
  width: 183px;
  height: 56px;
  padding: 15px 32px;
  display: flex;
}

.viewDetailsButton:hover {
  background: #231729;
  border-radius: 40px;
  gap: 4px;
  width: 183px;
  height: 56px;
  padding: 15px 32px;
}

.jobItemMainContentItemDetail {
  flex-direction: column;
  width: auto;
  display: flex;
}

.autoWidth {
  width: auto !important;
}

.jobItemMainContentItemSecondaryList {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row !important;
}

.basePagePrimaryCardLowerCardOneTypo {
  text-align: left;
  font-family: Avenir;
  font-size: 48px;
  font-weight: 800;
  line-height: 55.57px;
}

.basePagePrimaryCardLowerCardTwoTypo {
  letter-spacing: 2.5px;
  text-align: left;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.basPagePrimaryCardLowerCardOne {
  width: 376px;
  height: 134px;
  opacity: .9px;
  background: #fe720d;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  gap: 0;
  padding: 16px;
  display: flex;
}

.basPagePrimaryCardLowerCardOneTextContainer {
  flex-direction: column;
  display: flex;
}

.viewMoreDetailsArrow {
  width: 20px;
  height: 20px;
}

.jobItemTopLeftContainer {
  gap: 8pxs;
  flex-direction: column;
  display: flex;
}

.jobListOuterContainer {
  width: 1180x;
}

.jobItemUpperContainer {
  border: 1px;
  border-bottom: 1px solid #2d1d37;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: auto;
  padding: 24px 40px;
  display: flex;
}

a {
  text-decoration: none !important;
}

.applyButton {
  cursor: pointer;
  width: 132px;
  height: 56px;
}

.jobItemTopRightContainer {
  align-items: center;
  gap: 8px;
  display: flex;
}

.jobOpeningItem {
  border: 1px solid #2d1d37;
  border-radius: 8px;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  padding: 24px 40px;
  display: flex;
}

.jobItemMainContentItem {
  flex-direction: column;
  gap: 6px;
  display: flex;
}

.jobItemLowerContainer {
  justify-content: center;
  display: none;
}

.jobItemMainContentItemContent {
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.jobItemMainContentItemLabel {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.jobItemMainContentContainer {
  flex-direction: column;
  gap: 12px;
  max-width: 968px;
  padding: 12px 0;
  display: flex;
}

.currentOpeningLowerContainer {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.currentOpeningPrimaryContent {
  letter-spacing: .05em;
  text-align: left;
  color: #d1cfd2;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
}

.currentOpeningSecondaryContent {
  text-align: left;
  color: #fff;
  font-family: Manrope;
  font-size: 48px;
  font-weight: 700;
  line-height: 62.4px;
}

@media only screen and (width <= 1000px) {
  .hiringCardItem {
    width: 100%;
  }

  .scrollCardContainer {
    flex-wrap: wrap;
    display: flex;
  }

  .lowerServiceContainer {
    flex-direction: row;
  }

  .flowStepsArrowContainer {
    display: none;
  }

  .flowStepsArrowMbContainer {
    display: flex;
  }

  .paddedContainer {
    justify-content: center;
    width: 100%;
    padding: 16px;
    display: flex;
  }

  .normalPaddedContainer {
    width: 100%;
    padding: 16px;
  }
}

.testimonialList {
  gap: 12px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 1180px) {
  .currentOpeningOuterContainer {
    width: 100%;
  }
}

@media only screen and (width <= 800px) {
  .philosiphyListItem {
    align-items: center;
    width: 100%;
    min-width: 100%;
  }

  .servicesDropdownListContainer {
    padding: 12px !important;
  }

  .flowStepsListItem {
    flex-direction: column;
    gap: 24px;
    min-height: 800px;
    display: flex;
  }

  .scrollCard {
    width: 100%;
  }

  .jobItemUpperContainer {
    flex-direction: column;
    display: flex;
  }

  .nav {
    display: none !important;
  }

  .blogCardItemImage {
    width: 100%;
  }

  .dropdown {
    display: block !important;
  }

  .ourTeamPrimaryComp {
    flex-direction: column;
    display: flex;
  }

  .servicesTopContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .servicesContainer {
    height: auto;
  }

  .bottomFormContainer {
    width: 90%;
    height: auto;
    padding: 16px;
    display: flex;
    overflow: hidden;
  }

  .flowStepsCardContent {
    justify-content: center;
    width: 100%;
  }

  .aboutUsSec {
    line-break: auto;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .flowStepListContainer {
    max-height: 800px;
    display: block;
  }

  .flowSteps {
    width: 100%;
    height: auto;
  }

  .rearMostContainer {
    flex-direction: column;
    height: auto;
    display: flex;
  }

  .testimonialList {
    width: 100%;
  }

  .ourApproachCardContainer {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .blogCard {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 544px;
    height: auto;
    display: flex;
  }

  .aboutUsIcon {
    width: 100%;
  }

  .ourApproachCard {
    align-items: center;
    width: 100%;
    min-height: 220px;
    max-width: 100% !important;
  }

  .whoWeAre {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    display: flex;
  }

  .arrowsContainer {
    display: none;
  }

  .blogsPrimaryInnerContainer {
    flex-direction: column;
    gap: 16px;
    display: flex;
  }

  .blogPrimaryTypo, .blogMainTypo {
    text-align: center !important;
  }

  .testimonialListItem {
    flex-direction: column;
    gap: 12px;
    width: 100%;
    min-width: 100%;
    height: auto;
    display: flex;
  }

  .ourProcessRightContainer {
    width: 100%;
  }

  .clientImage {
    margin-top: 0;
  }

  .blogItemPrimaryTypo {
    text-align: center;
  }

  .mobileCentered {
    text-align: center;
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .typoMedium {
    max-height: auto !important;
  }

  .toolsetMainText {
    height: auto;
    max-height: auto !important;
  }

  .technicalToolsetSlider {
    width: 100%;
  }

  .testimonialLowerContainer {
    display: flex;
    flex-direction: column !important;
    gap: 12px !important;
  }

  .textCentered {
    text-align: center;
  }

  .blogItemMainTypo {
    text-align: center !important;
  }

  .innerOurProcessContainer {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  .mainBlogHeading {
    flex-direction: column;
    display: flex;
  }

  .mainBlog {
    text-align: center;
    justify-content: center;
    width: 100%;
  }

  .secondaryServicesListContainer {
    justify-content: center;
    width: 100%;
  }

  .partnersLogoContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: auto;
    display: flex;
  }

  .rearMostLeftContainer {
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .innovationListContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    display: flex;
  }

  .pioneerTwo {
    margin: auto;
  }

  .aboutUsSecContent {
    text-align: center;
    width: 100%;
  }

  .aboutUsSecMain {
    text-align: center !important;
  }

  .centerAllignedMb {
    text-align: center;
  }

  .technicalToolsetItemIcons {
    width: 100%;
  }

  .rearMostRightContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .philosiphyContainerMb {
    margin-top: 450px !important;
  }

  .topRearLeftContainer {
    align-items: center;
  }

  .noneDisplayedMb {
    display: none;
  }

  .rearMostLowestContainer {
    flex-direction: column;
    gap: 12px;
    display: flex;
  }

  .blogMiddleContainer {
    max-width: 100%;
    padding: 12px;
    overflow: hidden;
  }

  .noDisplayMb {
    display: none !important;
  }

  .rearMostLowerContainer {
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    display: flex;
  }

  .mainBottomIcon {
    width: 140px;
    height: 25px;
  }

  .blogMainContentContainer {
    width: 100%;
  }

  .blogsMainContainer {
    width: 100%;
    max-width: 100vw;
  }
}

@media only screen and (width <= 450px) {
  .baseServicesCardContainer {
    min-width: 100% !important;
    height: auto !important;
  }
}

@media only screen and (width <= 550px) {
  .imgServicesDropdownPrimaryContainer {
    display: none;
  }

  .firstIntoLowerImages {
    width: 100%;
    max-height: auto;
  }

  .jobOpeningItem {
    padding: 0;
  }

  .ourProcessView {
    width: 100%;
    max-width: 100%;
  }

  .basPagePrimaryCardLowerCardOne {
    width: 100%;
  }
}

.back-blog-main {
  cursor: pointer;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
  display: flex;
}

.back-blog-main-text {
  color: var(--Text-Secondary, #d1cfd2);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.blog-heading-div {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.blog-heading-text {
  color: var(--Text-Primary, #fff);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.blog-image-div {
  margin-bottom: 32px;
}

.blog-image {
  border-radius: 12px;
  align-self: stretch;
  width: 100%;
  height: 462px;
}

.blog-date-div {
  margin-bottom: 32px;
}

.blog-date {
  color: var(--Text-Secondary, #d1cfd2);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.blog-content {
  color: var(--Text-Secondary, #d1cfd2);
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.blog-main-div {
  max-width: 800px;
}

@media (width <= 768px) {
  .blog-heading-text {
    color: var(--Text-Primary, #fff);
    font-family: Manrope;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  .aboutUsContainer {
    gap: 80px;
  }

  .blog-main-div {
    max-width: 328px;
    padding: 16px;
  }
}
/*# sourceMappingURL=aboutus.18f723f6.css.map */
