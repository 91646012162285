html{
    min-height: 100vh;
    padding: 0;
}

body{
    min-height: 100vh;
    color: #fff;
    background: linear-gradient(180deg, #1A0E20 0%, #010013 100%);
}

.testimonialLowerContainer{
    overflow: hidden;
}

.baseBackground{
    background: linear-gradient(180deg, #1A0E20 0%, #010013 100%);
}

.typoColorWhite{
    color : rgba(217, 214, 223, 1) !important;
}

.accordianGapList{
    display: flex;
    flex-direction: column;
    gap: 30px;

    --bs-accordion-border-width : 0px;
}

.nonBorder{
    border: none;
}

.ourTeamContainer{
    display: flex;
    flex-direction: column;
    gap: 150px;
}

.baseBackgroundInherit{
    background: linear-gradient(180deg, #1A0E20 10%, #010013 90%) !important;
}

.accordianPaddedContent{
    padding: 16px;
    padding-top: 24px !important;
}

.accordianListItem{
    border-top: 1px solid rgba(154, 157, 170, 1);
    border-bottom: 1px solid rgba(154, 157, 170, 1);
    padding: 24px 16px;
}

.outerContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}

.nav{
    height: 84px;
    width: 1100px;

    display: flex;
    justify-content: space-between;
    padding: 12px ;
    color: #fff;
}

.letsTalkButton{
    width: 120px;
    height: auto;

    cursor: pointer;
}

.navbarIcons{
    width: 400px;
    height: auto;
}

.servicesListOuterContainer{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.optionsContainer{
    display: flex;
    gap: 12px;
}

.optionsItem{

}

.navButton{
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;

    width: 127px;
    height: 40px;

    background: #8D49E4;
    color: #FFFFFF;
  
    border: 1.5px solid #FFFFFF;
    border-radius: 15px;
    overflow: hidden;
}

.servicesContainer{
    width: 1180px;
    height: auto;
    opacity: 0px;
    gap: 24px;

    padding: 24px;
    display: flex;
    flex-direction: column;
}

.serviceList{
    width: 376px;
    height: 330px;
    gap: 0px;
    opacity: 0px;
    border: 1px solid;

    gap: 0px;
    border-radius: 8px;
    opacity: 0px;


    background: linear-gradient(90.61deg, #5F01BD 5%, #3C0475 115.73%);   
}

.detailsList{
    color: #FFFFFF;
}

.upperServicesContainer{
    display: flex;
    gap: 16px;
}

.blogPrimaryTypo{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}

.blogMainTypo{
    font-family: Manrope;
    font-size: 48px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: left;

    color: rgba(255, 255, 255, 1);
}

.viewAllBlog{
    width: 194px;
    /* height: 56px; */
    /* padding: 15px 32px 15px 32px; */
    gap: 4px;
    border-radius: 40px;
    border: 2px;
}

.mainBlog{
    display: flex;
    flex-direction: column;
}

.mainBlogHeading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blogCard{
    width: 544px;
    height: 200px;
    display: flex;
    gap: 32px;
}

.blogItemPrimaryTypo{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.05em;

    color: rgba(209, 207, 210, 1);
}

.blogItemMainTypo{
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    
    color: rgba(255, 255, 255, 1);
}

.blogItemContent{
    display: flex;
    flex-direction: column;
}

.blogCardItemImage{
    width: 200px;
    height: 200px;
    border-radius: 8px;
}

.blogCardContainer{
    max-width: 1,168px;
    height: auto;
    gap: 80px;
    display: flex;
    flex-wrap: wrap;
}

.blogsItemsMainList{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
}

.getInTouch{
    width: 154px;
    height: 46px;
    padding: 12px 16px 12px 16px;
    gap: 12px;
    border-radius: 24px;
    opacity: 0px;

    background: #8D49E4;

    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;

    cursor: pointer;

}

.lowerServiceContainer{
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
}

.servicesMainTypo{
    font-family: Raleway;
    font-size: 44px;
    font-weight: 600;
    line-height: 64px;
    text-align: left;
    color: #fff;
    line-break: auto;
}

.servicesPrimaryTypo{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.44px;
    letter-spacing: 3px;
    text-align: left;
    color: #CECED7;
}

.servicesTertiaryTypo{
    font-family: Avenir;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;   
    color: #CECED7;   

}

.servicesOuterContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}

.ourPhilosophyOuterContainer{
    display: flex;
    justify-content: center;
    margin-top: 150px;
    overflow-x: hidden;
}

.ourPhilosophyContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lowerPhilosiphyContainer{
    display: flex;
}

.philosiphyListItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap : 12px;
    width: 378px;
}

.mediumText{
    font-family: Raleway;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #D9D6DF;
}

.mediumSmallText{

    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #CDC9E4;
}

.mediumLargeText{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;

    color: #B8B3C1;
}

.partnersLogoContainer{
    display: flex;
    justify-content: space-evenly;
    padding: 24px;
    width: 1176px;
    height: 149px;
    gap: 0px;
    border: 1px solid #9A9DAA;
    border-radius: 8px;
    align-items: center;
}

.secondaryServicesListContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 1200px;
    justify-content: center;
}

.serviceItemMainText{
    margin-top: 5px;
}

.secondaryServicesListItem{
    width: 376px;
    height: auto;
    padding: 24px ;
    gap: 4px;
    border-radius: 12px ;
    border: 1px solid #9A9DAA;
    opacity: 0px;
    justify-content: flex-start;
}

.redirectSmallText{
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.86px;
    text-align: left;

    height: 100%;
    display: flex;
    align-items: center;

    color: #9747FF;
}

.learnMoreContainer{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 12px;
    width: auto;
}

.centerAlligned{
    text-align: center !important;
    justify-content: center;
}

.verticalCentered{
    display: flex;
    flex-direction: column;
    align-items: center !important;
}

.ourTeamsTabTypo{
    color: rgba(196, 199, 206, 1) !important;
}

.active{
    color: rgba(151, 71, 255, 1) !important;
    background-color: inherit !important;
    border-color: linear-gradient(180deg, #1A0E20 0%, #010013 100%) !important;
}

.teamMemberList{
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 24px !important;
}

.columnDirection{
    flex-direction: column;
}

.flexCentered{
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center;
}

.tab-pane:not(.show) {
    display: none;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-link {
    border: none !important;
}

.teamMember{
    height: 385px;
    width: 376px;
}

.ourTeamMainText{
    max-width: 600px;
    width: 100%;
    text-align: center !important;
    color: rgba(217, 214, 223, 1);
}

.innovationListContainer{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    margin-bottom: 24px;

    justify-content: center;
}

.pioneerTwo{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 370px;
    min-height: 370px;
    padding: 8px 24px;
    border-radius: 8px;
    opacity: 0.89;
    background: url("./../projectImages/PioneerTwo.svg");
    overflow: hidden;
}

.pioneerThree{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 370px;
    min-height: 370px;
    padding: 8px 24px;
    opacity: 0.89;
    border-radius: 8px;
    background: url("./../projectImages/teamMembers/atankshuCard.svg");
    overflow: hidden;
}

.engPioneerTwo{
    background: url("./../projectImages/teamMembers/rishabCard.svg") !important;
}

.engPioneerThree{
    background: url("./../projectImages/teamMembers/yuvrajCard.svg") !important;
}

.designPioneerTwo{
    background: url("./../projectImages/teamMembers/surjithCard.svg") !important;
}

.nonePadd{
    padding: 0px !important;
}

.ourTeamCardDetail{
    width: 376px;
    height: 107px;
    border-radius: 8px ;
    opacity: 0.89px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 24px 16px;
    background: linear-gradient(90.61deg, #5F01BD 5%, #B53EB1 115.73%);
}

.noStylemediumText{
    font-family: Raleway;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    max-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.noStylemediumSmallText{
    font-family: Avenir;
    font-size: 16px;
    font-weight: 350;
    line-height: 21.86px;
    max-height: 24px;
    text-align: left;

    color: #CDC9E4;
}

.testimonialsContainer{
    display: flex;
    flex-direction: column;
    gap:12px;
    max-width: 1100px;

    width: 100%;
}

.testimonialTopContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.testimonialTextContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.typoSmall{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.44px;
    letter-spacing: 3px;
    text-align: left;
    max-height: 14px;

    color: #C4C7CE;
}

.typoMedium{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;

    color: #B8B3C1;
}

.circularContainer{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #9A9DAA;
}

.arrowsContainer{
    display: flex;
    gap: 24px;
}

.lineHeightBig{
    line-height: 40px !important;
}

.accordion-button::after{
    background: url("./../projectImages/dropdownIcon.svg") !important;
    height: 40px !important;
    width: 40px !important;
}

.accordion-button:not(.collapsed){
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after{
    background: url("./../projectImages/dropdownIcon.svg") !important;
    height: 40px !important;
    width: 40px !important;
}

.lowerContainer{
    display: flex;
    gap: 18px;
}

.testimonialListItem{
    min-width: 776px;
    height: 259px;
    border-radius: 24px ;
    opacity: 0.1px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 24px 24px;

    background: #4f4872;
}

.typoMediumSmall{
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.32px;
    text-align: left;

    color: #FFFFFF;
}

.minorColored{
    color: #CDC9E4
}

.clientImage{
    height: 40px;
    width: auto;
    margin-top: 24px;
}

.blogMainContentContainer{
    display: flex;
    width: 900px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.blogTopContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.blogsMainContainer{
    display: flex;
    flex-direction: column;
    gap : 12px;
    max-width: 1100px;
    width: 100%;
}

.blogMiddleContainer{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    overflow: hidden;
}

.viewAllButton{
    margin: auto;
    width: 120px;
    height: auto;
    cursor: pointer;
}

.toolsListImage{
    margin-top: 24px;
}

.hiringCardItem{
    width: 1100px;
    height: 122px;
    gap: 0px;
    border-radius: 4px;
    opacity: 0px;
    background-color: #352a41;
    padding: 28px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-left: 8px solid #5F01BD;
}

.hiringCardText{
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.horizontallycenterAlligned{
    justify-content: center;
    text-align: center;
}

.rearMostContainer{
    width: 100%;
    height: 800px;
    border: 1px solid #9A9DAA;
    background-color: #5F01BD;
    padding: 24px 0px;
    display: flex;
    justify-content: space-between;
}

.rearMostLeftContainer{
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 80px;

}

.whiteColor{
    color : #fff
}

.secondaryRearLeftContainer{
    display: flex;
    flex-direction: column;
}

.topRearLeftContainer{
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.bottomLeftRear{
    width: 160px;
    height: 108px;
}

.sachhsoftIcon{
    width: 100px;
    height: auto;
}

.rearMostRightContainer{
    display: flex;
    flex-direction: column-reverse;
    padding: 8px 0px;
    overflow: hidden;
}

.bottomFormContainer{
    width: 708px;
    height: 672px;
    border-radius:12px ;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 28px;

    padding: 24px;

    padding-right: 100px;

    background-color: #fff;
}

.tag{
    display: flex;

    min-width: 130px;
    height: 38px;
    padding: 8px 16px 8px 16px;
    gap: 12px;
    border-radius: 24px;
    border: 1px solid black;
    opacity: 0px;

    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    overflow: hidden;

    color: #030114;
}

.boldMedium{
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: left;

    max-height: 18px;
}

.formTagsContainer{
    display: flex;
    gap: 12px;
}

.darkColor{
    color : #030114
}

.textInputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;

}

.inputLabel{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    
    height: 100%;
    vertical-align:middle;

    max-height: 16px;
    
}

.flowSteps{
    max-width: 544px;
    max-height: 544px;
}

.inputTypeText{
    min-height: 44px;
    width: 100%;
    display: flex;
    background-color: #fff;
    border: 1px solid #9A9DAA;
    border-radius: 6px;
}

.formRowContainer{
    display: flex;
    gap: 12px;
}

.textArea{
    min-height: 108px;
    width: 100%;
    display: flex;
    background-color: #fff;
    border: 1px solid #9A9DAA;
    border-radius: 6px;
    overflow: scroll;
}

.attachmentContainer{
    display: flex;
    gap: 4px;
}

.attachmentLinkIcon{
    width: 16px;
    height: 16px;
}

.submitButton{
    width: 115px;
    height: auto;
}

.columedContainer{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutUsSec{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    width: 100%;
    padding: 16px;
}

.aboutUsSecMain{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;

    max-width: 364px;
    height: auto;


    color: #D9D6DF;
}

.aboutUsSecContent{
    font-family: Avenir;
    font-size: 20px;
    font-weight: 350;
    line-height: 36px;
    text-align: left;

    color: #CDC9E4;

    width: 676px;
    height: auto;

}

.leftMover{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: auto;
}

.horizontalRow{
    min-width: 676px;
    height: 0px;
    border: 1px solid #9A9DAA;
    opacity: 0px;

}

.typoMediumLarge{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;

    color: #D9D6DF;
}

.typoBiggerSmall{
    font-family: Avenir;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;

    color: #CDC9E4;
}

.spacedFlex{
    display: flex;
    justify-content: space-between;
}

.ourApproachCard{
    max-width: 326px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap:16px
}

.outApproachCardIcon{
    width: 48px;
    height: 48px;
}

.outApproachCardContent{
    font-family: Avenir;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}

.ourApproachTitle{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.44px;
    letter-spacing: 3px;
    color: #C4C7CE;
}

.outApproachSecTitle{
    font-family: Raleway;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    color: #D9D6DF;
}

.capture{
    width: 100%;
    height: auto;
}

.ourBeliefTextContainer{
    display: flex;
    flex-direction: column;
}

.ourBeliefHeading{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.44px;
    letter-spacing: 3px;
    text-align: left;

    color: #C4C7CE;
}

.ourBeliefTertiary{
    font-family: Avenir;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    color: #CDC9E4;
}

.ourBeliefImage{
    bottom: 160px;
}

.absolute{
    position: absolute;
}

.meetTheTeamButton{
    width: 171px;
    height: 46px;
}

.ourBeliefContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.outBeliefButtonContainer{
    display: flex;
    justify-content: center;
    padding: 12px;
}

.aboutUsContainer{
    background: linear-gradient(180deg, #1A0E20 0%, #010013 100%);
    display: flex;
    flex-direction: column;
    gap: 120px;
}

.accordion{
    border-color: linear-gradient(180deg, #1A0E20 0%, #010013 100%) !important;
}

.fullContainer{
    width: 100%;
    height: auto;
}

.baseBackground{
    background: linear-gradient(180deg, #1A0E20 0%, #010013 100%);
}

.innovationPioneers{
    max-width: 370px;
}

.navItemsTypo{
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-decoration: none;

    color : rgba(255, 255, 255, 1);
}

.gappedTen{
    display: flex;
    gap: 25px;
}

.fullWidthCentered{
    width: 100%;
    justify-content: center;
}

.defaultMaxWidth{
    max-width: 1176px !important;
}

.ourTeamPrimaryCompText{
    max-width: 525px;
    width: 100%;
}

.ourDreamTeamIcon{
    width: 100%;
    height: 273px;
}

.ourTeamPrimaryComp{
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.flexedWrap{
    display: flex;
    flex-wrap: wrap;
}

.servicesLeftContainerMaxWidth{
    max-width: 680px;
    width: 100%;
    justify-content: center;
    padding: 18px;
}

.flowStepsCardContent{
    display: flex;
    flex-direction: column;
    max-width: 544px;
    justify-content: center;
    gap: 36px;
}

.flowStepsStep{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.05em;
    text-align: left;

    color: rgba(209, 207, 210, 1);
}

.flowStepsMainContent{
    font-family: Manrope;
    font-size: 48px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: left;

    color: rgba(209, 207, 210, 1);
}

.flowStepMbSlider{
    display: flex;
    justify-content: space-between;
}

.flowStepsArrowMbContainer{
    display: none;
}

.flowStepListContainer{
    display: flex;
    overflow: hidden;

    transition-duration: 1s;

    transition: transform 0.5s ease-in-out;

}

.flowStepsListItem{
    display: flex;
    gap: 48px;
    min-width: 100%;

    transition-duration: 1s;
}

.flowStepsContentDescription{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    color: rgba(209, 207, 210, 1);

}

.flowStepsContent{
    display: flex;
    flex-direction: column;
}

.flexDirectionColumn{
    display: flex;
    flex-direction: column;
}

.flowStepsArrowContainer{
    display: flex;
    gap: 16px;
}

.centeredtext{
    text-align: center;
}

.horizontalDivider{
    width: 476px;
    border: 1px solid rgba(154, 157, 170, 1);

    background-color: rgba(154, 157, 170, 1);
}

.lineBreak{
    line-break: auto;
}

.ourProcess{
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 150px;
}

.ourProcessView{
    width: 432px;
    height: 551.6px;
    border-radius: 24px;
}

.innerOurProcessContainer{
    display: flex;
    gap: 16px;
}

.ourProcessRightContainer{
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 500px;
    justify-content: center;
}

.technicalToolsetSlider{
    padding: 16px 0px;
    max-width: 1176px;
    width: 1000px;
    display: flex;
    gap: 24px;
    justify-content: space-between;

    overflow: hidden;
}

.sliderArrow{
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.technicalToolsetItemContainer{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 60px;
    overflow: hidden;
}

.technicalToolsetItemIcons{
    max-height: 36px;
    max-width: 160px;
}

.boostBussinessContainer{
    display: flex;
}

.rearMostLowerContainer{
    display: none;
}

.ourClientsViews{
    max-width: 240px;
    height: 60px;
}

.fullWidthCover{
    width: 100%;
    max-width: 100%;
}

.rearMostLowestContainer{
    display: none;
    width: 100%;
    height: auto;
}

.navbar-toggler-icon{
    background: url("./../projectImages/navbarToggleHead.svg") !important;
    width: 32px !important;
    height: 32px !important;
}

.exploreCareerButton{
    width: 288px;
    padding-top: 24px;

    cursor: pointer;
}

.fitInPlaceWidth{
    width: fit-content !important;
}

.careersPrimaryBlock{
    width: 100%;
}

.careersHorizontalScroll{
    background: linear-gradient(101.11deg, #8D49E4 0%, #B78CEE 100%);
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.careersHorizontalScrollUpperContainer{
    display : flex;
    flex-direction: column;
}

.careersHorizontalScrollPrimaryText{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(232, 219, 250, 1);
}

.baseFormFile{
    display: none;
}

.careersHorizontalScrollSecondaryText{
    font-family: Manrope;
    font-size: 48px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.scrollCardContainer{
    display: flex;
    gap: 24px;
    overflow: hidden;
}

.scrollCardIcon{
    width: 48px;
    height: 48px;
}

.scrollCardTypo{
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;

    color :rgba(255, 255, 255, 1);
}

.careerHorizontalInnerContainer{
    padding: 72px 0px;
}

.scrollCard{
    width: 280px;
    height: 230px;
    min-width: 280px;
    padding: 40px;
    gap: 40px;
    border-radius: 12px ;
    background: linear-gradient(110.39deg, rgba(229, 183, 251, 0.06) 0%, rgba(241, 216, 253, 0.06) 100%);
    border: 1px solid rgba(182, 138, 237, 1);

    display : flex;
    flex-direction: column;
    gap: 24px;
}

.careersPrimaryBlockMainTypo{
    font-family: Raleway;
    font-size: 64px;
    font-weight: 600;
    line-height: 83.2px;
    text-align: left;

    max-width: 800px;
    height: auto;

    color : rgba(255, 255, 255, 1);
}

.purpleColoredTypo{
    color: #8D49E4 !important;

}

.careersPrimaryBlockContentTypo{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: rgba(209, 207, 210, 1);

}

.currentOpeningOuterContainer{
    display: flex;
    flex-direction: column;
    gap: 36px;

    width: 1180px;
}

.currentOpeningUpperContainer{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.careerJobType{
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;

    color : rgba(255, 255, 255, 1);

}

.servicesPrimaryContentGradient{
    background: var(--Text-Gradient, linear-gradient(87deg, #D3B4FB 0%, #8D49E4 97.7%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.servicesTopLeftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.servicesTopContainer{
    display: flex;
    align-items: center;
    gap: 80px;
    align-self: stretch;
}

.servicesPrimaryContent{
    color: var(--Text-Primary, #FFF);
    font-family: Manrope;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.servicesSecondaryContent{
    color: var(--Text-Secondary, #D1CFD2);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
    
    align-self: stretch;
}

.updatedBaseServicesContainer{
    display: flex;
    max-width: 1208px;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    align-self: stretch;
}

.baseServicesLowerContainer{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 40px;
    align-self: stretch;
    flex-wrap: wrap;
}

.baseServicesCardContainer{
    display: flex;
    min-width: 436px;
    padding: 64px;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    flex: 1 0 0;
    height: 520px;

    border-radius: 12px;
    border: 1px solid #4A454D;
    background: linear-gradient(110deg, rgba(229, 183, 251, 0.01) 0%, rgba(241, 216, 253, 0.01) 100%);
}

.exploreMoreRightIcon{
    width: 20px;
    height: 20px;
}

.exploreMoreButtonContentTypo{
    color: var(--Text-Primary, #FFF);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 25.6px */
}

.imgServicesDropdownPrimaryContainer{
    width: 80px;
    height: 80px;
}

.servicetopDropdownItem{
    display: flex;
    gap: 56px;
}

.servicesDropdownListContainer{
    color: var(--Text-Secondary, #D1CFD2);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */

    display: flex;
    padding: 0px 144px 18px 152px;
    gap: 26px;
    align-self: stretch;
    flex-direction: column;
}

.servicesPagePrimaryInnerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;
    flex: 1 0 0;
}

.servicesPagePrimaryContent{
    display: flex;
    max-width: 800px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.servicesPagePrimaryMainContent{
    color: var(--Text-Primary, #FFF);
    font-family: Manrope;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.purpleColoredPrimaryMainContent{
    color: #8D49E4;
    background-clip: text;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}

.servicesPageSecondaryMainContent{
    color: var(--Text-Secondary, #D1CFD2);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
}

.exploreMoreServicesButton{
    display: flex;
    padding: 15px 32px;
    align-items: center;
    gap: 4px;

    border-radius: 40px;
    background: #8D49E4;

    cursor: pointer;
}

.updatedBaseServicesOuterContainer{
    display: flex;
    justify-content: center;
}

.baseServicesContentContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.baseServicesCardIcon{
    width: 120px;
    height: 120px;
}

.baseServicesCardContent{
    color: var(--Text-Secondary, #D1CFD2);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
    align-self: stretch;
}

.baseServicesCardMainHeading{
    color: var(--Text-Primary, #FFF);
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
    align-self: stretch;

    line-break: auto;
}

.careerJobExperience{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    color : rgba(209, 207, 210, 1);
}

.viewDetailsContent{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
    text-align: left;
    margin-top: 16px;

    color : rgba(255, 255, 255, 1);
}

.viewDetailsButton{
    display: flex;
    align-items: center;
    width: 183px;
    height: 56px;
    padding: 15px 32px 15px 32px;
    padding: 15px 32px 15px 32px;
    gap: 4px;
    border-radius: 40px;

    cursor: pointer;
}

.viewDetailsButton:hover{
    width: 183px;
    height: 56px;
    padding: 15px 32px 15px 32px;
    gap: 4px;
    border-radius: 40px;

    background: rgba(35, 23, 41, 1);
}

.jobItemMainContentItemDetail{
    display: flex;
    flex-direction: column;
    width: auto;
}

.autoWidth{
    width: auto !important;
}

.jobItemMainContentItemSecondaryList{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.basePagePrimaryCardLowerCardOneTypo{
    font-family: Avenir;
    font-size: 48px;
    font-weight: 800;
    line-height: 55.57px;
    text-align: left;    
}

.basePagePrimaryCardLowerCardTwoTypo{
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2.5px;
    text-align: left;
}

.basPagePrimaryCardLowerCardOne{
    width: 376px;
    height: 134px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0.9px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgba(254, 114, 13, 1);
    justify-content: center;
    padding: 16px;
}

.basPagePrimaryCardLowerCardOneTextContainer{
    display: flex;
    flex-direction: column;
}

.viewMoreDetailsArrow{
    width: 20px;
    height: 20px;
}

.jobItemTopLeftContainer{
    display: flex;
    flex-direction: column;
    gap: 8pxs;
}

.jobListOuterContainer{
    width: 1180x;
}

.jobItemUpperContainer{
    width: 100%;
    height: auto;
    padding: 24px 40px 24px 40px;
    gap: 8px;
    border: 1px ;
    border-bottom: 1px solid rgba(45, 29, 55, 1);


    display: flex;
    justify-content: space-between;
}

a{
    text-decoration: none !important;
}

.applyButton{
    width: 132px;
    height: 56px;

    cursor: pointer;
}

.jobItemTopRightContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.jobOpeningItem{
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;
    padding: 24px 40px 24px 40px;
    gap: 8px;
    border-radius: 8px;
    border-bottom: 1px solid rgba(45, 29, 55, 1);
    border : 1px solid rgba(45, 29, 55, 1);
}

.jobItemMainContentItem{
    display : flex;
    flex-direction: column;
    gap: 6px;
}

.jobItemLowerContainer{
    display: flex;
    justify-content: center;

    display: none;
}

.jobItemMainContentItemContent{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    color: rgba(209, 207, 210, 1);

}

.jobItemMainContentItemLabel{
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.jobItemMainContentContainer{
    max-width: 968px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0px;
}

.currentOpeningLowerContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.currentOpeningPrimaryContent{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(209, 207, 210, 1);
}

.currentOpeningSecondaryContent{
    font-family: Manrope;
    font-size: 48px;
    font-weight: 700;
    line-height: 62.4px;
    text-align: left;

    color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width : 1000px){
    .hiringCardItem{
        width: 100%;
    }

    .scrollCardContainer{
        display: flex;
        flex-wrap: wrap;
    }

    .lowerServiceContainer{
        flex-direction: row;
    }

    .flowStepsArrowContainer{
        display: none;
    }

    .flowStepsArrowMbContainer{
        display: flex;
    }

    .paddedContainer{
        width: 100%;
        padding: 16px;
        display: flex;
        justify-content: center;
    }

    .normalPaddedContainer{
        width: 100%;
        padding: 16px;
    }
}

.testimonialList{
    display: flex;
    gap : 12px;
    overflow: hidden;
}

.dropdown{
    /* display: none !important; */
}

@media only screen and (max-width: 1180px) {
    .currentOpeningOuterContainer{
        width: 100%;
    }
}

@media only screen and (max-width : 800px){
    .philosiphyListItem{
        align-items: center;
        width: 100%;
        min-width: 100%;
    }

    .servicesDropdownListContainer{
        padding: 12px !important;
    }

    .flowStepsListItem{
        display: flex;
        flex-direction: column;
        min-height: 800px;
        gap: 24px;
    }

    .scrollCard{
        width: 100%;
    }

    .jobItemUpperContainer{
        display: flex;
        flex-direction: column;
    }

    .nav{
        display: none !important;
    }

    .blogCardItemImage{
        width: 100%;
    }

    .dropdown{
        display: block !important;
    }

    .ourTeamPrimaryComp{
        display: flex;
        flex-direction: column;
    }

    .servicesTopContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .servicesContainer{
        height: auto;
    }

    .bottomFormContainer{
        display: flex;
        width: 90%;
        height: auto;
        overflow: hidden;
        padding: 16px;
    }

    .flowStepsCardContent{
        width: 100%;
        justify-content: center;
    }

    .aboutUsSec{
        display: flex;
        flex-direction: column;
        line-break: auto;
        align-items: center;
    }

    .flowStepListContainer{
        display: block;
        max-height: 800px;
    }

    .flowSteps{
        width: 100%;
        height: auto;
    }

    .rearMostContainer{
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .testimonialList{
        width: 100%;
    }

    .ourApproachCardContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blogCard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 544px;
        width: 100%;
        height: auto;
    }

    .aboutUsIcon{
        width: 100%;
    }

    .ourApproachCard{
        width: 100%;
        max-width: 100% !important;
        min-height: 220px;
        align-items: center;
    }

    .whoWeAre{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 12px;
    }

    .arrowsContainer{
        display: none;
    }

    .blogsPrimaryInnerContainer{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .blogPrimaryTypo{
        text-align: center !important;
    }

    .blogMainTypo{
        text-align: center !important;
    }

    .testimonialListItem{
        width: 100%;
        min-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .ourProcessRightContainer{
        width: 100%;
        /* max-width: 800px; */
    }

    .clientImage{
        margin-top: 0px;
    }

    .blogItemPrimaryTypo{
        text-align: center;
    }

    .mobileCentered{
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .typoMedium{
        max-height: auto !important;
    }

    .toolsetMainText{
        height: auto;
        max-height: auto !important;
    }

    .technicalToolsetSlider{
        width: 100%;
    }

    .testimonialLowerContainer{
        display: flex;
        flex-direction: column !important;
        gap: 12px !important;
    }

    .textCentered{
        text-align: center;
    }

    .blogItemMainTypo{
        text-align: center !important;
    }

    .innerOurProcessContainer{
        width: 100%;
        max-width: 100%;
        justify-content: center;
        /* flex-direction: column; */
    }

    .mainBlogHeading{
        display: flex;
        flex-direction: column;
    }

    .mainBlog{
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .secondaryServicesListContainer{
        width: 100%;
        justify-content: center;
    }

    .partnersLogoContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .rearMostLeftContainer{
        padding-left: 0;
        display: flex;
        align-items: center;
    }

    .innovationListContainer{
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .pioneerTwo{
        margin: auto;
    }

    .aboutUsSecContent{
        width: 100%;
        text-align: center;
    }

    .aboutUsSecMain{
        text-align: center !important;
    }

    .centerAllignedMb{
        text-align: center;
    }

    .technicalToolsetItemIcons{
        width: 100%;
    }

    .rearMostRightContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .philosiphyContainerMb{
        margin-top: 450px !important;
    }

    .topRearLeftContainer{
        align-items: center;
    }

    .noneDisplayedMb{
        display: none;
    }

    .rearMostLowestContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .blogMiddleContainer{
        max-width: 100%;
        overflow: hidden;
        padding:12px ;
    }

    .noDisplayMb{
        display: none !important;
    }

    .rearMostLowerContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px;
    }

    .mainBottomIcon{
        height: 25px;
        width: 140px;
    }

    .blogMainContentContainer{
        width: 100%;
    }

    .blogsMainContainer{
        width: 100%;
        max-width: 100vw;
    }
}

@media only screen and (max-width : 450px){

    .baseServicesCardContainer{
        min-width: 100% !important;
        height: auto !important;
    }
}

@media only screen and (max-width : 550px){

    .imgServicesDropdownPrimaryContainer{
        display: none;
    }

    .firstIntoLowerImages{
        width: 100%;
        max-height: auto;
    }

    .jobOpeningItem{
        padding: 0px;
    }

    .ourProcessView{
        width: 100%;
        max-width: 100%;
    }

    .basPagePrimaryCardLowerCardOne{
        width: 100%;
    }

}

.back-blog-main{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.back-blog-main-text{
  color: var(--Text-Secondary, #D1CFD2);
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 170%; /* 23.8px */
}

.blog-heading-div{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-heading-text{
    color: var(--Text-Primary, #FFF);
font-family: Manrope;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 130%; /* 62.4px */
}

.blog-image-div{
margin-bottom: 32px;
}

.blog-image{
height: 462px;
width: 100%;
align-self: stretch;
border-radius: 12px;
}

.blog-date-div{
    margin-bottom: 32px;
}

.blog-date{
color: var(--Text-Secondary, #D1CFD2);

font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 170%; /* 23.8px */

}

.blog-content{
    color: var(--Text-Secondary, #D1CFD2);

font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 32px */
}

.blog-main-div{
    max-width: 800px;
}

@media (max-width: 768px) {
    .blog-heading-text {
        color: var(--Text-Primary, #FFF);
        font-family: Manrope;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 52px */
    }

    .aboutUsContainer{
    gap: 80px;
}
    .blog-main-div{
    max-width: 328;
    padding: 16px;
}
}

